<template>
  <div>
    <member-breadcrumb :title="title"/>
    <div>
      <el-form :inline="true" :model="query" class="pull-left" onsubmit="return false">

        <el-form-item label="项目编号">
          <el-input v-model="query.projectId"></el-input>

        </el-form-item>

        <el-form-item label="状态">
          <el-select v-model="query.payStatus" placeholder="请选择">
            <el-option
                    key=""
                    label="全部"
                    value="">
            </el-option>
            <el-option
                    v-for="(name,value) in payStatusMap"
                    :key="value"
                    :label="name"
                    :value="value">
            </el-option>
          </el-select>

        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="pagingSearch">查询</el-button>
        </el-form-item>
      </el-form>

      <div class="clear"></div>
    </div>


    <el-table
            :data="data.list"
            border
            class="mt10"
            @sort-change="elSort"
            :default-sort="{prop: 'id', order: 'descending'}"
            style="width: 100%">


      <el-table-column
              prop="id"
              align="center"
              label="订单号"
              sortable="custom" :sort-orders="[ 'descending','ascending']"
      >

      </el-table-column>


      <el-table-column
              prop="projectId"
              align="center"

              label="项目编号"
      >
        <template slot-scope="scope">
          <router-link :to="{name:'projectDetail', params:{id:scope.row.projectId}}">{{scope.row.projectId}}
          </router-link>
        </template>
      </el-table-column>

      <el-table-column
              prop="payMoney"
              align="right"
              header-align="center"
              width="100"
              label="金额"
      >
        <template slot-scope="scope">
          {{scope.row.payMoney/ 100}}
        </template>
      </el-table-column>


      <el-table-column
              prop="type"
              align="center"
              label="状态"
              width="100"
      >
        <template slot-scope="scope">
          {{payStatusMap[scope.row.payStatus.toString()]}}
        </template>
      </el-table-column>

      <el-table-column
              prop="type"
              align="center"
              label="付款时间"
              width="160"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.payStatus">{{dateUtil.format(scope.row.payTime)}}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column
              prop="type"
              align="center"
              label="下单时间"
              width="160"
      >
        <template slot-scope="scope">
          {{dateUtil.format(scope.row.createdAt)}}
        </template>
      </el-table-column>


    </el-table>
    <my-pagination :pager="data" @goPage="pagingTurn"></my-pagination>
  </div>
</template>

<script>
  import urlUtil from 'tdw-url-util'
  import dateUtil from 'tdw-date-util'
  import MyPagination from '../common/MyPagination'
  import MemberBreadcrumb from '../common/MemberBreadcrumb'

  export default {
    name: 'PayOrderList',
    components: {MemberBreadcrumb, MyPagination},
    data () {
      return {
        title: '支付订单',
        usePagingPlugin: true,
        query: {
          pageNum: urlUtil.getPara('pageNum', 1),
          pageSize: 20,
          orderColumn: 'id',
          orderType: 'desc',
          projectId: urlUtil.getPara('projectId'),
          payStatus: urlUtil.getPara('payStatus')
        },
        queryUrl: '/payOrder/list.do',
        data: {list: []},
        dateUtil,
        payStatusMap: {
          '0': '未支付',
          '1': '已支付'
        }
      }
    }
  }
</script>

<style scoped>

</style>